import { Item } from './Item';

export const Section = ({ name, description, icon, items }) => {

    const displayItems = () => {
        return items.map((item, index) => (
            <div key={index} className="mb-4 mx-4">
                <Item {...item} />
            </div>
        ));
    }

    return (
        <div className="mb-5">
            <div className="h2 text-dark border-bottom mb-0"> {icon} {name} </div>
            <div className="text-muted mb-4"> {description} </div>
            { displayItems() }
        </div>
    )
};