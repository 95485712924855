import { Container } from 'react-bootstrap';

import { Header } from './components/Header';
import { Content } from './components/Content';
import { Footer } from './components/Footer';

export const App = () => {
    return (
        <>
            <Container className="mt-0 mt-lg-5 p-0 rounded shadow bg-light">
                <Header />
                <Content />
            </Container>
            <Footer />
        </>
    );
};