import { Row, Col, Image } from 'react-bootstrap';
import { BiRightArrow } from 'react-icons/bi';
import { FaCoins, FaCog, FaInfo } from 'react-icons/fa';

export const Item = ({ image, componentName, specs = [], price }) => {
    const displaySpecs = () => {
        return specs.map((item, index) => (
            <div className="h5 ms-2" key={index}> <BiRightArrow size="20" /> {item} </div>
        ));
    }

    return (
        <div className="p-4 shadow bg-section rounded">
            <div className="h3 mb-4"> <FaCog size="35" className="text-dark bg-warning border border-dark p-1 rounded" /> {componentName} </div>
            <Row>
                <Col lg className="text-center mb-5 mb-lg-0">
                    <Image src={image} height="300" fluid rounded className="shadow" alt="component-image" />
                </Col>
                <Col lg>
                    { specs.length !== 0 && <div className="h4 mb-4"> <FaInfo size="35" className="text-dark bg-warning border border-dark p-1 rounded" /> Adatok </div>}
                    { specs.length !== 0 && <div className="mb-4"> { displaySpecs() } </div>}
                    <div className="h4"> <FaCoins size="35" className="text-dark bg-warning border border-dark p-1 rounded" /> {price} </div>
                </Col>
            </Row>
        </div>
    );
};