import { Row, Col } from 'react-bootstrap';

export const Footer = () => {
    return (
        <div className="my-5">
            <Row className="justify-content-center text-center">
                <Col lg="8" className="border-top border-dark text-dark pt-1">
                    &copy; Natty Bt. 2021
                </Col>
            </Row>
        </div>
    )
};