import { FaDesktop, FaLaptop, FaWindows, FaPrint } from 'react-icons/fa';
import Monitor from '../assets/img/monitor.png';
import Laptop from '../assets/img/laptop.png';
import Desktop_i5 from '../assets/img/asztali2.png'
import Desktop_i3 from '../assets/img/asztali.png';
import Printer from '../assets/img/nyomtato.png';

import { Section } from "./Section"

export const Content = () => {
    return (
        <div className="p-3">
            <Section
            name="Monitorok"
            icon={<FaDesktop />}
            description={"Az elavult 4:3-as képarányú 15\"-os monitorok leváltására javasolt modellek"}
            items={[
                {
                    componentName: "SAMSUNG C24F390FH Ívelt Monitor",
                    image: Monitor,
                    specs: ["23.5\"", "1920x1080", "1x VGA, 1x HDMI"],
                    price: "41 900 Ft",
                }
            ]}
            />

            <Section
            name="Laptopok"
            icon={<FaLaptop />}
            description={"Elavult laptopok cseréjére javasolt notebookok"}
            items={[
                {
                    componentName: "DELL Latitude 3510",
                    image: Laptop,
                    specs: ["15.6\" 1920x1080", "Intel® Core™ i3-10110U 2.10GHz", "Intel® UHD Graphics", "8GB DDR4", "256GB SSD", "Windows 10 Pro"],
                    price: "261 000 Ft",
                }
            ]}
            />

            <Section
            name="Asztali Gépek"
            icon={<FaWindows />}
            description={"Elavult asztali gépek cseréjére javasolt számítógépek"}
            items={[
                {
                    componentName: "DELL Inspiron 3881 i5 Mini Tower",
                    image: Desktop_i5,
                    specs: ["Intel® Core™ i5-10210", "Intel® UHD Graphics 630", "8GB DDR4", "250GB SSD", "Windows 10 Pro"],
                    price: "310 548 Ft",
                },
                {
                    componentName: "DELL Inspiron 3881 i3 Mini Tower",
                    image: Desktop_i3,
                    specs: ["Intel® Core™ i3-10100", "Intel® UHD Graphics 630", "8GB DDR4", "250GB SSD", "Windows 10 Pro"],
                    price: "264 287 Ft",
                }
            ]}
            />

            <Section
            name="Nyomtatók"
            icon={<FaPrint />}
            description="Javasolt nyomtatók"
            items={[
                {
                    componentName: "KYOCERA ECOSYS P3145DN",
                    image: Printer,
                    specs: [],
                    price: "127 900 Ft",
                }
            ]}
            />
        </div>
    )
};
